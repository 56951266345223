<template>
  <v-card width="220" class="fill-height d-flex flex-column justify-space-between" flat>
    <div>
      <!-- LOGO -->
      <v-card-text class="d-flex align-center pb-3">
        <div class="cursor-pointer d-flex align-center" @click="$router.push('/dashboard')" style="height: 55px; margin-top: -10px">
          <v-img src="/static/images/kipish-logo.png" width="190px"/>
        </div>
        <div class="d-flex align-center"></div>
      </v-card-text>
      <!-- NAV ITEMS -->
      <v-card-text v-if="!loading" class="px-0 py-0">
        <section v-for="(section, index) of navSections" :key="index">
          <v-divider style="margin-top: -1px" class="mb-6"/>
          <span class="grey--text ml-3">{{ section.name }}</span>

          <template v-for="(navItem, navKey) of section.items">
            <router-link v-if="!Array.isArray(navItem.permissions) || navItem.permissions.length && navItem.permissions.some(p => userPermissions.includes(p))" :key="navKey"
                    :to="`${navItem.path}`"
                    tag="div"
                    :class="[
                      {'active-link': $route.path.startsWith(navItem.path)},
                      navItem.icon
                    ]"
                    class="link d-flex cursor-pointer"
                    style="position:relative;"
            >
              <nav>
                <div class="d-flex align-center px-6 py-3" style="width: 100%">
                  <heroicon class="nav-icon mr-2" :name="navItem.icon" fill="#858D9D"/>
                  <span :class="navKey !== 0 ? 'space-menu' : 'nospace-menu'" class="nav-label text-14 font-normal" style="color:#4A4C56">{{ navItem.name }}</span>
                </div>
                <div class="counter" v-if="navItem.counter">
                  {{ navItem.counter }}
                </div>
              </nav>
            </router-link>
          </template>
        </section>

      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "app-drawer",
  data: () => ({
    userPermissions: [],
    loading: true,
    exitDialog: false,
    navSections: [
      {
        name: 'Главная',
        items: [
          {icon: 'dashboard',       name: 'Дашборд',        path: '/dashboard'},
          {icon: 'reports',         name: 'Репортажи',      path: '/reports',                 permissions: ['albums_view', 'albums_edit']},
          {icon: 'video',           name: 'Видео',          path: '/videos',                  permissions: ['video_reports_view', 'video_reports_edit']},
          {icon: 'establishments',  name: 'Заведения',      path: '/establishments',          permissions: ['establishments_view', 'establishments_edit']},
          {icon: 'news',            name: 'Новости',        path: '/news',                    permissions: ['posts_view', 'posts_edit']},
        ]
      },
      {
        name: 'Прочее',
        items: [
          {icon: 'roles',           name: 'Роли',           path: '/roles',                   permissions: ['roles_view', 'roles_edit']},
          {icon: 'users',           name: 'Пользователи',   path: '/users',                   permissions: ['admins_view', 'admins_edit']},
          {icon: 'camera',          name: 'Фотографы',      path: '/photographers',           permissions: ['photographers_view', 'photographers_edit']},
          {icon: 'banners',         name: 'Реклама',        path: '/advertising',             permissions: ['advertising_view', 'advertising_edit']},
          {icon: 'poster',          name: 'События',        path: '/posters',                 permissions: ['posters_view', 'posters_edit']},
          {icon: 'contacts',        name: 'Контакты',       path: '/contacts'},
          {icon: 'msg',             name: 'Отзывы',         path: '/feedback'},
          {icon: 'establishments',         name: 'Бронь',          path: '/booking'},
        ]
      },
      {
        name: 'Справочники',
        items: [
          {icon: 'cities',          name: 'Города',         path: '/cities',                  permissions: ['city_dict_view', 'city_dict_edit']},
          {icon: 'lang',            name: 'Языки',          path: '/languages',               permissions: ['language_dict_view', 'language_dict_edit']},
          {icon: 'book',            name: 'Заведения',      path: '/category/establishment',  permissions: ['categories_view', 'categories_edit']},
          {icon: 'kitchen',         name: 'Кухни',          path: '/category/kitchen',        permissions: ['categories_view', 'categories_edit']},
          {icon: 'poster',          name: 'События',        path: '/category/events',         permissions: ['categories_view', 'categories_edit']},
        ]
      },
      {
        name: 'SEO',
        items: [
          {icon: 'robot',           name: 'Robots',         path: '/robots'}, //                  permissions: ['robots_txt_view', 'robots_txt_edit']},
          {icon: 'lang',            name: 'URL пути',       path: '/urls',                    permissions: ['url_view', 'url_edit']},
          {icon: 'redirect',        name: 'Редиректы',      path: '/redirects'} //               permissions: ['redirects_view', 'redirects_edit']},
        ]
      }
    ]
  }),

  mounted() {
    this.getUser();
  },

  created() {
    this.fetchFeedbackCounter();
  },

  computed: {
    ...mapGetters(["userId"]),
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
      this.exitDialog = false
    },

    getUser() {
      this.$http.get(`/users/${this.userId}`)
        .then(response => {
          this.userPermissions = response.data.role.permissions.map(permission => permission.code);
        })
        .catch(error => {
          console.error('Error fetching user permissions:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchFeedbackCounter() {
      this.$http.get('/feedback/count?viewed=false')
        .then(r => {
          const count = r.data?.count ?? 0;
          if(count) {
            this.navSections
              .find(section => section.name === 'Прочее').items
                .find(item => item.name === 'Отзывы').counter = count;
          }
        })
    }
  },
};
</script>

<style lang="scss">
.v-input__slot {
  background: #FAFAFA !important;
}

.link {
  .counter {
    position: absolute;
    top: 25%;
    right: 1.4em;
    font-size: 15px;
    font-weight: 700;
    color: whitesmoke;
    background: #e13232;
    line-height: 1.6em;
    border-radius: 50%;
    width: 1.6em;
    text-align: center;
    box-shadow: 0 0 10px 0 rgb(165 23 23);
  }
}

.active-link {
  background: #F5F5F5;
  border-right: 4px solid #000000;

  .nav-icon {
    fill: #000000;
  }
  .nav-label {
    color: #000000;
  }
}

.space-menu {
  margin-top: 5px;
}

.nospace-menu {
  margin-top: -5px !important;
}
</style>
